@import 'variables';

$sides: top, bottom, left, right;
$sizes: 0, 4, 6, 8, 10, 12, 16, 20, 24, 40;

@each $side in $sides {
  @each $size in $sizes {
    .padding-#{$size} {
      padding: #{$size}px !important;
    }
    .margin-#{$size} {
      margin: #{$size}px !important;
    }
    .padding-#{$side}-#{$size} {
      padding-#{$side}: #{$size}px !important;
    }
    .margin-#{$side}-#{$size} {
      margin-#{$side}: #{$size}px !important;
    }
  }
}

body {
  @include font-16-regular;

  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h2 {
  @include font-18-regular;

  margin: 8px 0;
}

.text-align-center {
  text-align: center;
}

.font-24-regular {
  @include font-24-regular;
}

.font-20-bold {
  @include font-20-bold;
}

.clickable {
  cursor: pointer !important;
}

.header {
  &-user {
    &-name {
      font-weight: bold;
    }

    &-company {
      text-transform: uppercase;
    }
  }

  &-lang-select {
    color: $color-white !important;

    .MuiSelect-outlined {
      padding-bottom: 12px !important;
      padding-top: 13px !important;
    }
  }

  .MuiGrid-item.clickable {
    display: inline-flex !important;
  }

  &-tag {
    color: $color-white !important;
    background-color: rgba($color-primary, 75%) !important;
  }
}

.link {
  color: $color-blue !important;
  text-decoration: none !important;

  .MuiButton-startIcon {
    margin-bottom: 4px;
  }
}

.MuiAutocomplete-listbox ul {
  padding: 0 !important;
  margin: 0 !important;

  li.MuiAutocomplete-option {
    padding: 0 16px;
    box-sizing: border-box;
    outline: 0;
    display: flex;
    cursor: pointer;
  }
}

.status {
  border-radius: 4px !important;

  &-blue {
    background-color: rgba($color-light-blue, 25%) !important;
  }
  &-orange {
    &-light {
      background-color: rgba($color-primary, 25%) !important;
    }
    &-dark {
      background-color: rgba($color-primary, 75%) !important;
    }
  }
  &-green {
    &-light {
      background-color: rgba($color-green, 25%) !important;
    }
    &-dark {
      background-color: rgba($color-green, 75%) !important;
    }
  }
  &-gray {
    background-color: $color-light-gray !important;
  }
  &-red {
    background-color: rgba($color-red, 25%) !important;
  }
}

.grid {
  &-block {
    border-radius: 4px;
    border: 1px solid $color-light-gray;
  }

  &-label {
    text-align: right;
    color: $color-secondary;
  }
}

.MuiDataGrid-cell--editable {
  cursor: pointer;
}

.height-100 {
  height: 100%;
}

.width-100 {
  width: 100%;
}

.table-input {
  vertical-align: middle !important;
  .MuiOutlinedInput-root {
    height: 28px;
  }

  .MuiFormHelperText-root {
    margin-left: 0;
  }
}

.MuiDataGrid-cell--editing {
  & .MuiInputBase-root {
    height: 100%;
  }
}

.Mui-error {
  background-color: rgba($color-red, 10%);
  color: $color-primary;
}

.order-selection-details-wrapper {
  margin: 8px;
  border: 1px solid $color-light-gray;
  border-radius: 4px;
}

.hidden {
  visibility: hidden;
}

.wordwrap .Mui-TableHeadCell-Content-Wrapper {
  white-space: normal !important;
  word-wrap: break-word;
}

tr.MuiTableRow-footer {
  background-color: rgb(242, 249, 255);
}

.nowrap {
  text-wrap: nowrap;
}
