$color-primary: #ff801c;
$color-secondary: #515151;
$color-black: #000000;
$color-dark-gray: #b1b3b3;
$color-gray: #c8c9c7;
$color-light-gray: #d9d9d6;
$color-white: #ffffff;
$color-blue: #0288d1;
$color-light-blue: #90caf9;
$color-green: #66bb6a;
$color-red: #ca6079;

@font-face {
  font-family: 'Intro-Regular';
  src:
    local('Intro-Regular'),
    url('../../public/Intro-Regular.woff2') format('woff2');
  font-display: swap;
}

@mixin font-16-regular {
  font-size: 16px;
  font-family: 'Intro-Regular', sans-serif;
  font-weight: normal;
  line-height: 24px;
  letter-spacing: 0.1px;
}

@mixin font-18-regular {
  font-size: 18px;
  font-family: 'Intro-Regular', sans-serif;
  font-weight: normal;
  line-height: 24px;
  letter-spacing: 0.1px;
}

@mixin font-20-regular {
  font-size: 20px;
  font-family: 'Intro-Regular', sans-serif;
  font-weight: normal;
  line-height: 24px;
  letter-spacing: 0.1px;
}

@mixin font-20-bold {
  font-size: 20px;
  font-family: 'Intro-Regular', sans-serif;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0.1px;
}

@mixin font-24-regular {
  font-size: 24px;
  font-family: 'Intro-Regular', sans-serif;
  font-weight: normal;
  line-height: 32px;
  letter-spacing: 0.1px;
}
